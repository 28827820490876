@import url('https://fonts.googleapis.com/css?family=Secular+One');
@import url('https://fonts.googleapis.com/css?family=Rubik');

:root{
  --background: #4d4d4d;
  --foreground: #e6e6e6;
  --dark: #303030;
}

.App {
  text-align: center;
  color: white;
  font-family: 'Rubik', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.splash-screen {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  background-color: var(--background);
  color: var(--foreground);
  font-family: 'Secular One', sans-serif;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(32px + 2vmin);
  background-color: var(--dark);
  height: 10vh;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  font-family: 'Rubik', sans-serif;
}

.Odds-text {
  font-size: calc(12px + 2vmin);
  font-family: 'Rubik', sans-serif;
  background-color: var(--foreground);
  color: var(--background);
  border-radius: 10%;
  padding:10px;
  transition: all 0.35s ease-in;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(12px + 2vmin);
  /* font-family: 'Rubik', sans-serif; */
  width: 100%;
}

.Bet-input-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* .Bet-input-text {
  font-size: calc(12px + 2vmin);
  width: 80%;
  border-top-left-radius: 8;
  border-bottom-left-radius: 8;
} */